import { useMountEffect } from "@react-hookz/web";
import type { RealEyeSdk } from "https://app.realeye.io/sdk/js/testRunnerEmbeddableSdk-1.3.js";

import { config } from "scmp-app/data";

declare global {
  interface Window {
    reSdk?: RealEyeSdk;
  }
}

export const useRealEyeSdk = () => {
  useMountEffect(() => {
    const loadSdk = async () => {
      try {
        const { EmbeddedPageSdk } = await import(
          /* webpackIgnore: true */ "https://app.realeye.io/sdk/js/testRunnerEmbeddableSdk-1.3.js"
        );
        window.reSdk = new EmbeddedPageSdk(false);
      } catch (error) {
        console.error("Failed to load RealEye SDK", error);
      }
    };
    if (!window.reSdk && config.general.env !== "production") {
      void loadSdk();
    }
  });
};
